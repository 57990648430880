import { json } from '@remix-run/node';
import { Outlet, useLoaderData } from '@remix-run/react';

import { useSegmentInit } from '~/hooks/useSegmentInit';
import { authenticator } from '~/services/auth.server';

import type { LoaderFunctionArgs } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';

export const meta: MetaFunction = () => [
  {
    tagName: 'link',
    rel: 'canonical',
    href: 'https://app.raffle.ai',
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let user = await authenticator.isAuthenticated(request);

  return json({ isAuthenticated: Boolean(user) });
};

export default function View() {
  const { isAuthenticated } = useLoaderData<typeof loader>();

  useSegmentInit({ shouldLogout: !isAuthenticated });

  return <Outlet />;
}
